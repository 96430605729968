import React, { useCallback, useMemo, useState } from "react";
import "../node_modules/github-markdown-css/github-markdown.css";
import "../node_modules/highlight.js/styles/xcode.css";
import { MdFile, MdPage } from "./pages/MdPage";
import { useEffect } from "react";
import { INavLink, INavLinkGroup } from "@fluentui/react";
import {
  BaseLayout,
  NavigationMenu,
  RouteType,
  LandingPage
} from "../../../EASX.Components.Library/ClientApp/src";
import "./styles/App.css";

export const App: React.FunctionComponent = () => {
  const [mdFiles, setMdFiles] = useState<MdFile[]>();

  const [navLinkGroups, setNavLinkGroups] = useState<INavLinkGroup[]>([
    {
      name: "Documents",
      links: [],
    },
  ]);

  useEffect(() => {
    if (mdFiles) {
      setNavLinkGroups(getNavLinkGroups(mdFiles));
    }
  }, [mdFiles]);

  useEffect(() => {
    const list = (window as any).MD_FILES_LIST as string[];
    const pages = list.map((fileName) => {
      return {
        file: fileName,
        name: fileName.replace(/.md/g, "").replace(/-/g, " "),
        path: fileName.replace(/.md/g, "").toLowerCase(),
      };
    });
    setMdFiles(pages);
  }, [setMdFiles]);

  const renderPages: RouteType[] = useMemo(
    () => [
      {
        exact: true,
        path: "/",
        render: () => <LandingPage title="EAS Documentation" minWidth={640} />,
      },
      {
        path: "/:pageName",
        render: () => <MdPage mdFiles={mdFiles} />,
      },
    ],
    [mdFiles]
  );

  const handleLinkClick = useCallback(
    (history: any) => (ev?: React.MouseEvent, item?: INavLink) => {
      ev && ev.preventDefault();
      const url =
        item && item.url === "" && item.links && item.links.length > 0
          ? item.links[0].url
          : item?.url;
      history.push(url ?? "");
    },
    []
  );

  const renderNavigationMenu = useCallback(
    (isDarkTheme: boolean, setDarkTheme: any) => {
      return (
        <NavigationMenu
          appName="EAS Documentation"
          handleLinkClick={handleLinkClick}
          isDarkTheme={isDarkTheme}
          setIsDarkTheme={setDarkTheme}
          navLinkGroups={navLinkGroups}
        />
      );
    },
    [navLinkGroups, handleLinkClick]
  );

  return (
    <BaseLayout
      pages={renderPages}
      renderNavigationMenu={renderNavigationMenu}
    />
  );
};

function getNavLinkGroups(mdFiles: MdFile[]): INavLinkGroup[] {
  const groups: INavLinkGroup[] = [{ links: [] }];
  mdFiles.forEach((page) => {
    let parentLinks: INavLink[] = groups[0].links;
    const nameParts = page.name.substr(1).split("/");
    const pathParts = page.path.substr(1).split("/");
    let pathName = "";
    while (nameParts.length > 1) {
      const key = nameParts.shift()!;
      const path = pathParts.shift()!;
      pathName += "/" + path;
      const existing = parentLinks.find((l) => l.name === key);
      if (existing) {
        existing.links = existing.links || [];
        existing.isExpanded = true;
        parentLinks = existing.links;
      } else {
        const parentLink: INavLink = {
          key,
          name: key,
          links: [],
          isExpanded: true,
          url: "",
        };
        parentLinks.push(parentLink);
        parentLinks = parentLink.links!;
      }
    }
    const newLink = {
      key: page.path,
      name: page.name.substr(page.name.lastIndexOf("/") + 1),
      url: page.path,
      icon: "TextDocument",
    };
    const existing = parentLinks.find((l) => l.key === newLink.name);
    if (existing) {
      Object.assign(existing, newLink);
    } else {
      parentLinks.push(newLink);
    }
  });
  return groups;
}
