import { createTheme } from "@fluentui/react";

export const lightTheme = createTheme({
  palette: {
    themePrimary: "#323a8e",
    themeLighterAlt: "#f4f5fb",
    themeLighter: "#d4d6ed",
    themeLight: "#b2b6dd",
    themeTertiary: "#7279bc",
    themeSecondary: "#434a9c",
    themeDarkAlt: "#2d3481",
    themeDark: "#262c6d",
    themeDarker: "#1c2050",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#f3f2f1",
    neutralLight: "#edebe9",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#d0d0d0",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#a19f9d",
    neutralSecondary: "#605e5c",
    neutralPrimaryAlt: "#3b3a39",
    neutralPrimary: "#323130",
    neutralDark: "#201f1e",
    black: "#000000",
    white: "#ffffff",
  },
});

export default lightTheme;
