import React, { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import raw from "rehype-raw";
import rehypeHighlight from "rehype-highlight";
import { useLocation } from "react-router-dom";
import { ProgressIndicator } from "@fluentui/react";
import styles from "./MdPage.module.scss";
import rehypeUrls from "rehype-urls";
import { Url } from "url";

export type MdPageProps = {
  mdFiles?: MdFile[];
};

export type MdFile = {
  file: string;
  name: string;
  path: string;
};

const transformLinkUri = (uri: string) => uri.toLowerCase().replace(".md", "");

export const MdPage: React.FC<MdPageProps> = ({ mdFiles }) => {
  var [markdown, setMarkdown] = useState("");
  var [pageTitle, setPageTitle] = useState<string>();
  let { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (mdFiles) {
      setIsLoading(true);
      const mdFile = mdFiles.find((f) => f.path === pathname?.toLowerCase());
      if (!!mdFile) {
        setPageTitle(mdFile.name?.substr(1).replaceAll("/", " - "));
        fetch("/docs/" + mdFile.file)
          .then((res) => res.text())
          .then((md) => {
            setMarkdown(md);
            setIsLoading(false);
            setError("");
          })
          .catch(() => {
            setIsLoading(false);
            setError("This page wasn`t found.");
          });
      } else {
        setIsLoading(false);
        setError("This page wasn`t found.");
      }
    }
  }, [mdFiles, pathname]);

  const transformImageUri = useCallback(
    (uri: string) => {
      const currentPath = pathname.substr(0, pathname.lastIndexOf("/") + 1);
      return uri.replace("./", "/docs" + currentPath);
    },
    [pathname]
  );

  return (
    <div>
      {isLoading ? (
        <ProgressIndicator />
      ) : error ? (
        <div>
          <h1 style={{ color: "red", marginLeft: "40px" }}>{error}</h1>
        </div>
      ) : (
        <>
          {pageTitle && <h1 className={styles.PageHeader}>{pageTitle}</h1>}
          <ReactMarkdown
            remarkPlugins={[gfm]}
            rehypePlugins={[raw, rehypeHighlight, [rehypeUrls, blankExternal]]}
            children={markdown}
            className="markdown-body markdownPage"
            transformLinkUri={transformLinkUri}
            transformImageUri={transformImageUri}
          />
        </>
      )}
    </div>
  );
};

function blankExternal(url: Url, node: any) {
  if (url.host && url.host !== window.location.host) {
    node.properties.target = "_blank";
  }
}
