import { ITheme } from "@fluentui/react/lib/Styling";

/**
 * Returns the css defining all variables for color and fonts in the theme.
 * These can then be reused in css by using var(--theVar).
 * @param theme Theme to get values from.
 * @param includeScssVars Set to add scss vars to access all css vars using scss
 */
export function generateThemeCssVars(
  theme: ITheme,
  includeScssVars?: boolean
): string {
  // Note: Reused from https://codepen.io/xugao/pen/wvKrmYo

  const capitalize = (s: string) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  const tokensToStyleObject = (
    tokens: any,
    prefix?: string,
    style: { [name: string]: string } = {}
  ) => {
    for (const name in tokens) {
      if (tokens.hasOwnProperty(name)) {
        const varName = prefix
          ? `${prefix}${name === "default" ? "" : capitalize(name)}`
          : `--${name}`;
        const varValue = tokens[name];

        if (varValue && typeof varValue === "object") {
          tokensToStyleObject(varValue, varName, style);
        } else {
          style[varName] = varValue;
        }
      }
    }
    return style;
  };

  function convertToCssVar(theme: ITheme) {
    const styles: string[] = [];
    const styleObject = tokensToStyleObject(theme);
    Object.keys(styleObject).forEach((key) => {
      if (typeof styleObject[key] !== "boolean") {
        styles.push(`${key}: ${styleObject[key]};`);
      }
    });
    return styles;
  }

  const cssVars = convertToCssVar(theme);
  let cssText = cssVars.join("\n") + "\n";
  if (includeScssVars) {
    // also add scss vars
    cssVars.forEach((cssVar) => {
      const varName = cssVar.split(":")[0];
      const scssName = varName.substring(2);
      cssText += `$${scssName}: ${varName};\n`;
    });
  }

  return cssText;
}
