import React, { useCallback } from "react";
import { Text } from "@fluentui/react/lib/Text";
import { Stack } from "@fluentui/react";
import Particles from "react-tsparticles";
import type { Engine } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import { useWindowSize } from "react-use";

export type LandingPageProps = {
    title: string;
    minWidth?: number;
}

export const LandingPage: React.FC<{ title: string, minWidth?: number }> = ({ title, minWidth }: LandingPageProps) => {

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  const { width } = useWindowSize();

  if (minWidth && width < minWidth){
    return null;
  }

  return (
    <>
      <Stack horizontalAlign="center" verticalAlign="center" styles={{ root: { height: "100%", minWidth: "600px" } }}>
      <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            fullScreen: {
              enable: false
            },
            particles: {
              number: {
                value: 10,
                density: {
                  enable: true,
                  value_area: 300,
                },
              },
              opacity: {
                value: 0.2,
                animation: {
                  enable: true,
                  mode: "random",
                  startValue: 'random',
                  minimumValue: 0.5,
                  speed: 1
               }
              },
              line_linked: {
                enable: true,
                color: "#777",
                distance: 150,
                opacity: 0.6,
                width: 1
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "out",
                },
                speed: 1,
              },
              shape: {
                type: ["image"],
                image: [
                  {
                    src: "/assets/eas-logo.png",
                    height: 40,
                    width: 40,
                  },
                ],
              },
              color: {
                value: "#CCC",
              },
              size: {
                value: 50,
                random: true,
                anim: {
                  enable: false,
                  speed: 4,
                  size_min: 1,
                  sync: true,
                },
              },
            },
            retina_detect: false,
          }}
        />
        <Text style={{ position: "absolute" }} variant="mega">{title}</Text>
      </Stack>
    </>
  );
};
